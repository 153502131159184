<template>
  <div class="text-black">
    <div class="p-3">
      <div class="flex justify-end items-center">
        <div class="text-black fs-14 fw-600 mr-3 cursor-pointer" @click="showModal(true)">
          <svg
            class="mr-1"
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.76131 12.5987 9 12.5987C9.23869 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.05211 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67709 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67709 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
              fill="#03267A"
            />
          </svg>
          Upload biên bản
        </div>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2"
        >
          <path
            d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
            fill="#03267A"
          />
        </svg>
        <a class="text-sm text-black fw-700 cursor-pointer" @click="addTQM">Tạo yêu cầu thông qua mổ</a>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        row-class-name="text-black text-sm cursor-pointer"
        header-cell-class-name="text-black text-base font-semibold"
        @row-click="viewDetail"
      >
        <el-table-column prop="mdt_date" label="Ngày hội chẩn" :formatter="formatDMY"></el-table-column>
        <el-table-column label="Phòng hội chẩn">
          <template class="text-center" slot-scope="scope">
            <span>{{ scope.row.mdt_room_name || (scope.row.mdt_room ? scope.row.mdt_room.name : '') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chủ tọa">
          <template class="text-center" slot-scope="scope">
            <span>{{ (scope.row.mdt_room && scope.row.mdt_room.preside && scope.row.mdt_room.preside.name ) || scope.row.preside }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Thư ký"
          width="200px"
        >
          <template
            class="text-center"
            slot-scope="scope"
          >
            <span>{{ scope.row.doctor.name }}</span>
          </template>
        </el-table-column>-->
        <el-table-column label="Biên bản" class-name="text-center">
          <template class="text-center" slot-scope="scope">
            <div v-if="scope.row.operation">
              <span
                class="mx-2 txt-pri underline cursor-pointer"
                @click.stop="openUrlTQM({recordData: scope.row.operation})"
              >Tóm tắt TQM</span>
            </div>
            <div v-if="scope.row.concluding">
              <span
                class="mx-2 txt-pri underline cursor-pointer"
                @click.stop="openPrintingUrl({recordData: scope.row.concluding.multilang})"
              >BBHC</span>
            </div>
            <div v-if="scope.row.pdf_url">
              <span
                class="mx-2 txt-pri underline cursor-pointer"
                @click.stop="openFile(scope.row.pdf_url)"
              >Xem BBHC</span>
            </div>
            <div v-if="scope.row.pdf_url_2">
              <span
                class="mx-2 txt-pri underline cursor-pointer"
                @click.stop="openFile(scope.row.pdf_url_2)"
              >Xem tóm tắt TQM</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-content-end mt-3">
        <el-pagination
          v-if="list"
          background
          layout="prev, pager, next"
          :total="mdtPaging && mdtPaging.total"
          :page-size="parseInt(mdtPaging && mdtPaging.per_page)"
          @current-change="handleCurrentPage"
        ></el-pagination>
      </div>
      <div></div>
    </div>

    <ModalCreateTQM ref="ModalCreateTQM" />
    <ModalUpload :type="'TQM'" @onSubmit="onUpload($event)" />
    <ModalViewTQM
      :loading="loading"
      @refresh="showMdtDiseases"
      @refreshList="getMdtDiseases"
      :data="data"
      :type="'TQM'"
      @onSubmit="onUpdate($event)"
    />
  </div>
</template>

<script>
import appUtils from '../../../../utils/appUtils'
import ModalCreateTQM from './ModalCreateHoiChanTQM.vue'
import ModalUpload from './ModalUploadFiles.vue'
import ModalViewTQM from './ViewPhieuUpload.vue'
export default {
  name: 'ListTQM',
  props: ['mr_id'],
  components: { ModalCreateTQM, ModalUpload, ModalViewTQM },
  data () {
    return {
      mdtId: null,
      loading: false,
      list: [],
      data: null,
      mdtPaging: null,
      appUtils
    }
  },
  mounted () {
    this.getMdtDiseases()
  },
  methods: {
    handleCurrentPage (page) {
      let query = Object.assign({}, this.$route.query)
      query.page = page
      this.$router.replace({ query }).catch(_ => {})
      this.getMdtDiseases()
    },
    openFile (url) {
      if (!url) return
      window.open(url)
    },
    async onUpdate (event) {
      const formData = new FormData()
      for (var key in event) {
        formData.append(key, event[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateMdtDiseases(this.mdtId, formData)
        .then(r => {
          this.showMdtDiseases()
          this.getMdtDiseases()
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
        })
    },
    async onUpload (event) {
      const formData = new FormData()
      for (var key in event) {
        formData.append(key, event[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileMdtDiseaes(formData)
        .then(r => {
          this.getMdtDiseases()
          this.$toast.open({
            message: 'Upload file thành công',
            type: 'success'
          })
        })
    },
    showModal (show) {
      window.$('#ModalUploadHoiChanTQM').modal(show ? 'show' : 'hide')
    },
    async openUrlTQM ({ recordData, print }) {
      // window.open(url.operation.pdf)
      if (!recordData || !recordData?.file_name_printing) {
        this.$message({
          type: 'error',
          message: this.$t('Không tìm thấy dữ liệu')
        })
        return
      }
      window.open(recordData?.file_name_printing)

      // try {
      //   const fileName = recordData?.file_name_printing
      //   const res = await this.$rf.getRequest('DoctorRequest').getEmrDocument({
      //     fileName
      //   })

      //   if (res.data) {
      //     if (print) {
      //       const printWindow = window.open(res.data?.documentLink)
      //       printWindow.print()

      //       printWindow.onafterprint = function () {
      //         printWindow.close()
      //       }
      //     } else {
      //       window.open(res.data?.documentLink)
      //     }
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    },
    openUrlBBHC (url) {
      window.open(url.concluding.pdf)
    },
    async getMdtDiseases () {
      this.loading = true
      try {
        const params = {
          limit: 50,
          mr_id: this.mr_id,
          type: 1,
          sort: 'mdt_date',
          page: this.$route.query.page || 1,
          sort_by: 'desc'
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getMdtDiseases(params)
        this.list = res.data?.data
        this.mdtPaging = res.data
      } finally {
        this.loading = false
      }
    },
    viewDetail (item) {
      if (item.pdf_url || item.pdf_url_2) {
        this.mdtId = item.id
        this.showMdtDiseases()
        window.$('#ModalViewUploadHoiChanTQM').modal(item ? 'show' : 'hide')
      } else {
        this.$router.push({
          name: 'MdtDiseaseDetail',
          params: { id: item.id },
          query: { type: 'TQM' }
        })
      }
    },
    async showMdtDiseases () {
      this.loading = true
      try {
        const id = this.mdtId
        await this.$rf
          .getRequest('DoctorRequest')
          .showMdtDiseases(id)
          .then(r => {
            this.data = r.data
          })
      } finally {
        this.loading = false
      }
    },
    addTQM () {
      this.$refs.ModalCreateTQM.openDialog(this.mr_id, 'TQM')
    },
    formatDMY (row) {
      return row.mdt_date && window.moment(row.mdt_date).format('DD/MM/YYYY')
    },
    async openPrintingUrl ({ recordData, fileName, print = false }) {
      const requestPrintingUrl = async ({ fileName, print }) => {
        try {
          const res = await this.$rf
            .getRequest('DoctorRequest')
            .getEmrDocument({
              fileName
            })
          if (res.data) {
            if (print) {
              const printWindow = window.open(res.data?.documentLink)
              printWindow.print()

              printWindow.onafterprint = function () {
                printWindow.close()
              }
            } else {
              window.open(res.data?.documentLink)
            }
          }
        } catch (error) {
          console.log(error)
        }
      }

      if (recordData) {
        const data = recordData?.find(
          item => item?.lang === appUtils.getLangId(this.$lang)
        )
        if (!data || typeof data === 'undefined') {
          this.$message({
            type: 'error',
            message: this.$t(
              'Không tìm thấy dữ liệu theo ngôn ngữ hiển thị đang chọn'
            )
          })
          return
        }
        window.open(data?.file_name_printing)
        // await requestPrintingUrl({ fileName: data?.file_name_printing, print })
        return
      }

      if (!fileName) {
        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_notfound'),
          type: 'error'
        })
        return
      }

      await requestPrintingUrl({ fileName, print })
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #ffffff;
  /* Neutral/300 */

  border: 1.5px solid #b4b4c4;
  border-radius: 10px;
  color: #616673;
}
label {
  margin-bottom: 10px;
}
.btn-save {
  height: 50px;
  width: 190px;
  border-radius: 8px;
  padding: 8px, 16px, 8px, 16px;
}
</style>
