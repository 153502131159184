const dataDemo = [
  {
    id: 1,
    name: 'Phòng khám Melatec'
  },
  {
    id: 2,
    name: 'Phòng khám Medelab'
  },
  {
    id: 3,
    name: 'Mitalab'
  },
  {
    id: 4,
    name: 'Greenlab'
  },
  {
    id: 5,
    name: 'Phòng khám đa khoa Thu Cúc'
  },
  {
    id: 6,
    name: 'Phòng xét nghiệm Huyết học 101'
  },
  {
    id: 7,
    name: 'Phòng xét nghiệm Huyết học 201'
  },
  {
    id: 8,
    name: 'Phòng xét nghiệm Vi sinh 102'
  },
  {
    id: 9,
    name: 'Phòng xét nghiệm Vi sinh 202'
  },
  {
    id: 10,
    name: 'Phòng xét nghiệm Tổng hợp 103'
  },
  {
    id: 11,
    name: 'Phòng xét nghiệm Tổng hợp 203'
  },
  {
    id: 12,
    name: 'Khoa Chẩn đoán hình ảnh, Bệnh viện K'
  },
  {
    id: 13,
    name: 'Phòng khám đa khoa Hồng Ngọc'
  },
  {
    id: 14,
    name: 'Phòng xét nghiệm Bác sĩ Trung'
  }
]

export default dataDemo
