<template>
  <el-dialog :visible.sync="dialogVisible" fullscreen :before-close="handleClose">
    <div>
      <div class="container">
        <div class="mb-3 d-flex align-items-center">
          <div v-if="query === 'bienban'">
            <label
              for="HoiChan"
              :class="query === 'TQM' ? 'd-flex align-items-center mr-2 fs-16 text-gray-400' : 'd-flex align-items-center mr-4 fs-16 txt-grey-800'"
            >
              <input
                type="radio"
                id="HoiChan"
                :value="0"
                class="mr-2 cursor-pointer"
                v-model="form.type"
              />Hội chẩn
            </label>
          </div>
          <div v-if="query === 'TQM'">
            <label
              for="TQM"
              :class="query === 'bienban' ? 'd-flex align-items-center mr-2 fs-16 text-gray-400' : 'd-flex align-items-center mr-2 fs-16 txt-grey-800'"
            >
              <input
                type="radio"
                id="TQM"
                :value="1"
                class="mr-2 cursor-pointer"
                v-model="form.type"
              />Thông qua mổ
            </label>
          </div>
        </div>
        <div class="fs-16 txt-grey-800 mb-3 fw-500">
          <div class="mb-2">Phòng Hội chẩn</div>
          <el-select class="w-full" placeholder="Chọn phòng" v-model="form.mdt_room_id">
            <el-option v-for="item in mdt_rooms" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="mb-3">
          <label class="fs-14 fw-500 txt-grey-900 required">Ngày hội chẩn</label>
          <input
            v-model="form.date"
            type="date"
            class="form-control fs-16 fw-500 txt-grey-900"
            placeholder="Nhập tên bệnh nhân"
          />
        </div>
        <div class="mb-3">
          <label class="fs-16 fw-500 txt-grey-800 required">Tên ca bệnh</label>
          <input
            v-model="form.name"
            type="text"
            class="form-control fs-16 fw-500 txt-grey-900"
            placeholder="Nhập tên ca bệnh"
          />
        </div>

        <div class="mb-2">
          <p class="fs-16 fw-600 txt-grey-900">Thông tin bệnh nhân</p>
        </div>
        <div class="mb-3">
          <label class="fs-14 fw-500 txt-grey-900 required">Tên bệnh nhân</label>
          <input
            v-model="form.patient_name"
            type="text"
            class="form-control fs-16 fw-500 txt-grey-900"
            placeholder="Nhập tên bệnh nhân"
          />
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-md-4">
              <label for="nam" class="fs-14 fw-500 txt-grey-900 required">Giới tính</label>
            </div>
            <div class="col-md-8">
              <label for="bd" class="fs-14 fw-500 txt-grey-900 required">Ngày sinh</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 d-flex align-items-center">
              <label for="nam" class="fs-14 fw-500 txt-grey-900 required d-md-none">Giới tính</label>
              <label for="nam" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
                <input type="radio" id="nam" :value="1" class="mr-2" v-model="form.patient_gender" />Nam
              </label>
              <label for="nu" class="d-flex align-items-center mr-2 fs-16 txt-grey-800">
                <input type="radio" id="nu" :value="2" class="mr-2" v-model="form.patient_gender" />Nữ
              </label>
            </div>
            <div class="col-md-8">
              <label for="bd" class="fs-14 fw-500 txt-grey-900 required d-md-none">Ngày sinh</label>
              <input
                type="date"
                name="bd"
                id="bd"
                placeholder="Chọn ngày"
                class="form-control fs-16 fw-500 txt-grey-900"
                v-model="form.patient_birthday"
              />
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="fs-14 fw-500 txt-grey-900">Địa chỉ</label>
          <input
            type="text"
            class="form-control fs-16 fw-500 txt-grey-900"
            placeholder="Nhập địa chỉ"
            v-model="form.patient_address"
          />
        </div>
        <div class="mb-3">
          <p class="fs-16 fw-600 txt-grey-900">Thông tin ca bệnh</p>
          <div class="mb-3">
            <label class="fs-14 fw-500 txt-grey-900 required">Vào viện ngày:</label>
            <input
              type="datetime-local"
              class="form-control fs-16 fw-500 txt-grey-900"
              placeholder="Chọn ngày"
              v-model="form.admission_date"
            />
          </div>
          <div class="mb-3">
            <div
              class="fs-14 fw-500 txt-grey-900 mb-2"
            >Chọn mẫu {{ query === 'bienban' ?' Biên bản hội chẩn':'Thông qua mổ'}}</div>
            <el-select
              clearable
              filterable
              v-model="form.doc_template_id"
              class="w-full"
              placeholder="Chọn mẫu phiếu"
              @change="handleChangeDocTemplate"
              remote
              :remote-method="getDocsTemplateList"
              :loading="loading"
            >
              <el-option
                v-for="item in docsTemplateList"
                :key="item.id + 'md'"
                :label="handleGetTemLabel(item)"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="mb-3">
            <label class="fs-14 fw-500 txt-grey-900 required">Chẩn đoán</label>
            <input
              type="text"
              class="form-control fs-16 fw-500 txt-grey-900"
              placeholder
              v-model="form.patient_diagnostic"
            />
          </div>
          <div class="mb-3">
            <label class="fs-14 fw-500 txt-grey-900">Các điều trị trước đây</label>
            <EditorInput
              id="indication-hc"
              :prop_text="form.indication"
              @onChangeContent="(content) => form.indication = content"
            />
          </div>
          <div class="mb-3">
            <label class="fs-14 fw-500 txt-grey-900 required">
              Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm
              sóc người bệnh
            </label>
            <EditorInput
              id="patient_diseases_current_cure-hc"
              :prop_text="form.patient_diseases_current_cure"
              @onChangeContent="(content) => form.patient_diseases_current_cure = content"
            />
          </div>
          <div class="mb-3">
            <label class="fs-14 fw-500 txt-grey-900">Kết luận</label>
            <EditorInput
              id="conclusion-hc"
              :prop_text="form.conclusion"
              @onChangeContent="(content) => form.conclusion = content"
            />
          </div>
          <div class="mb-3">
            <label class="fs-14 fw-500 txt-grey-900">Hướng điều trị</label>
            <EditorInput
              id="treatment-hc"
              :prop_text="form.treatment"
              @onChangeContent="(content) => form.treatment = content"
            />
          </div>

          <div class="mb-3">
            <label
              for="p-surgery_date"
              class="fs-14 fw-500 txt-grey-900"
            >Ảnh minh họa ({{ totalImage }}/5)</label>
            <div class="d-flex">
              <div class="upload-container" v-for="(imgObj, i) in imageFiles" :key="i + 'amh'">
                <div class="upload-btn" @click="uploadFile(i)">
                  <img v-if="imgObj.url" :src="imgObj.url" alt class="img-x" />
                  <template v-else>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.7369 2.76175H8.08489C6.00489 2.75375 4.30089 4.41075 4.25089 6.49075V17.2277C4.20589 19.3297 5.87389 21.0697 7.97489 21.1147C8.01189 21.1147 8.04889 21.1157 8.08489 21.1147H16.0729C18.1629 21.0407 19.8149 19.3187 19.8029 17.2277V8.03775L14.7369 2.76175Z"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.4756 2.75V5.659C14.4756 7.079 15.6246 8.23 17.0446 8.234H19.7986"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.6416 9.90918V15.9502"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.9869 12.2642L11.6419 9.90918L9.29688 12.2642"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="robo-14-500 txt-pri">Chọn hình ảnh</span>
                  </template>
                </div>
                <input v-model="imgObj.name" class="upload-input" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center pb-5">
          <button
            @click="onSubmit"
            class="btn bg-pri bd-pri text-white btn-save"
            :disabled="
            loading ||
            !form.patient_name ||
            !form.patient_birthday ||
            !form.patient_gender ||
            !form.patient_diagnostic ||
            !form.patient_diseases_current_cure ||
            !form.admission_date ||
            !form.date ||
            isNaN(form.type)
          "
          >Thêm</button>
        </div>
      </div>
      <input
        type="file"
        ref="inputFile"
        accept=".jpeg, .jpg"
        @change="handleFileSelected"
        multiple
        class="d-none"
      />
    </div>
  </el-dialog>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'
import { mapState } from 'vuex'

import _ from 'lodash'
import appUtils from '../../../../utils/appUtils'
import { MENU_EMR_TEMPLATE } from '../../../../utils/constants'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'

export default {
  name: 'ModalCreateHoiChanTQM',
  components: { EditorInput },
  mixins: [mixinGetPersonInfo],
  data () {
    return {
      query: '',
      listBADT: {},
      room_id: null,
      mdt_rooms: [],
      loading: false,
      person_id: null,
      dialogVisible: false,
      form: {
        name: '',
        mdt_room_id: null,
        patient_gender: 1,
        patient_name: '',
        patient_birthday: '',
        patient_address: '',
        patient_diagnostic: '',
        patient_diseases_current_cure: '',
        treatment: '',
        admission_date: '',
        indication: '',
        conclusion: '',
        type: 0,
        date: window.moment().format('YYYY-MM-DD'),
        code: '',
        doc_template_id: null
      },
      imageFiles: {},
      currentImage: 1,
      appUtils,
      docsTemplateList: []
    }
  },
  computed: {
    ...mapState({
      person: state => state.person
    }),
    totalImage () {
      let total = 0
      for (const i in this.imageFiles) {
        const img = this.imageFiles[i]
        if (img.url) total++
      }
      return total
    }
    // formatterAdmissionDate: {
    //   get () {
    //     return this.listBADT.medical_record_master?.reception_at
    //       ? appUtils.formatInputDateTime(
    //           this.listBADT.medical_record_master?.reception_at
    //       )
    //       : this.person?.admission_hospital_date
    //         ? appUtils.formatInputDateTime(this.person?.admission_hospital_date)
    //         : ''
    //   },
    //   set (newDate) {
    //     this.form.admission_date = newDate
    //     return newDate
    //   }
    // }
  },
  watch: {
    listBADT (listBADT) {
      this.form.patient_name = listBADT.person?.name || ''
      this.form.patient_gender = listBADT.person?.gender || 1
      this.form.patient_birthday = listBADT.person?.birthday || ''
      this.form.patient_address = this.getAddress(listBADT.person) || ''
      this.form.admission_date = listBADT.medical_record_master?.reception_at
        ? appUtils.formatInputDateTime(
            listBADT.medical_record_master?.reception_at
        )
        : this.person?.admission_hospital_date
          ? appUtils.formatInputDateTime(this.person?.admission_hospital_date)
          : ''
      this.form.patient_diagnostic =
        listBADT?.medical_record_master?.treat_dept_main_name || ''
      this.form.treatment =
        listBADT?.medical_record_master?.treatment_direction || ''
      this.form.patient_diseases_current_cure =
        listBADT?.medical_record_master?.exam_summary || ''
      const extendName = listBADT.medical_record_master?.treat_dept_main_name
        ? ' / ' + listBADT.medical_record_master?.treat_dept_main_name
        : ''
      this.form.name = listBADT.person?.name + extendName
    },
    query () {
      this.getDocsTemplateList()
    }
  },
  mounted () {
    this.makeImageSize()
    this.getMdtRooms()
    this.getDocsTemplateList()
  },
  methods: {
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    openDialog (item, query) {
      this.query = query
      if (query === 'bienban') {
        this.form.type = 0
      }
      if (query === 'TQM') {
        this.form.type = 1
      }

      this.dialogVisible = true
      this.mr_id = item
      this.getDetailBADT(item)
    },
    handleClose () {
      this.dialogVisible = false
    },
    async getMdtRooms () {
      const params = {
        limit: 200,
        clinic_id: this.$globalClinic.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getMdtRooms(params)
        .then(r => {
          this.mdt_rooms = r.data?.data
        })
        .finally(() => {
          return true
        })
    },
    async getDetailBADT (id) {
      const res = await this.$rf.getRequest('DoctorRequest').getDetailBADT(id)
      this.listBADT = res.data
      const list = res.data
      this.person_id = list?.person_id
    },
    //
    async onSubmit () {
      const { form, imageFiles } = this
      form.date = appUtils.formatInputDate(form.date)
      form.admission_date = appUtils.formatInputDateTime(form.admission_date)

      const formData = new FormData()
      for (const key in form) {
        if (!_.isNil(form[key])) formData.set(key, form[key])
      }
      for (const i in imageFiles) {
        const imgObj = imageFiles[i]
        if (!imgObj) continue
        if (imgObj.fileRaw) formData.set('image_' + i, imgObj.fileRaw)
        if (imgObj.name) formData.set('image_name_' + i, imgObj.name)
      }
      formData.append('multilang[0][lang]', 1)
      formData.append(
        'multilang[0][patient_diagnostic]',
        this.form.patient_diagnostic
      )
      formData.append('multilang[0][indication]', this.form.indication)
      formData.append(
        'multilang[0][patient_diseases_current_cure]',
        this.form.patient_diseases_current_cure
      )
      formData.append('multilang[0][conclusion]', this.form.conclusion)
      formData.append('multilang[0][treatment]', this.form.treatment)

      this.addMdtDiseases(formData)
    },
    async showMdtRoom () {
      let self = this
      self.loading = true
      self.$rf
        .getRequest('DoctorRequest')
        .showMdtRoom(this.room_id)
        .then(r => {
          self.room = r.data
        })
        .catch(e => {
          console.log(e)
          self.$router.push({ name: 'ListMdt' })
        })
        .finally(() => {
          self.loading = false
        })
    },
    async addMdtDiseases (form) {
      this.loading = true
      try {
        const _tempRoom = this.mdt_rooms?.find(
          item => item.id === this.form.mdt_room_id
        )
        const preside = _tempRoom.preside?.name

        form.set('preside', preside)
        form.set('person_id', this.person_id)
        form.set('mr_id', this.mr_id)
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .addMdtDiseases(form)
        this.goToDetail(res.data.id)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    goToDetail (id) {
      if (this.form.type === 0) {
        this.$router.push({
          name: 'MdtDiseaseDetail',
          params: { id: id },
          query: { type: 'bienban' }
        })
      } else {
        this.$router.push({
          name: 'MdtDiseaseDetail',
          params: { id: id },
          query: { type: 'TQM' }
        })
      }
    },
    makeImageSize () {
      const images = {}
      for (let i = 1; i <= 5; i++) {
        images[i] = {
          url: this.mdt_disease && this.mdt_disease['image_url_' + i],
          name: this.mdt_disease && this.mdt_disease['image_name_' + i]
        }
      }
      this.imageFiles = images
    },
    uploadFile (index) {
      this.currentImage = index
      this.$refs.inputFile.click()
    },
    handleFileSelected (evt) {
      const file = evt.target.files[0]
      const { currentImage: index } = this
      this.$set(this.imageFiles, index, {
        ...this.imageFiles[index],
        url: URL.createObjectURL(file),
        fileRaw: file
      })
      evt.target.value = null
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type:
            this.query === 'TQM'
              ? Number(MENU_EMR_TEMPLATE[2].value)
              : Number(MENU_EMR_TEMPLATE[1].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then(r => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              item =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find(item => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}
      this.form = {
        ...this.form,
        conclusion: contentObj.conclusion?.value,
        patient_diseases_current_cure:
          this.query === 'TQM'
            ? contentObj.medical_history?.value
            : contentObj.patient_diseases_current_cure?.value,
        treatment: contentObj.treatment?.value
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    }
  }
}
</script>

<style scoped>
.form-control fs-16 fw-500 txt-grey-900 {
  background: #ffffff;
  /* Neutral/300 */

  border: 1.5px solid #b4b4c4;
  border-radius: 10px;
  color: #616673;
}

label {
  margin-bottom: 10px;
}

.btn-save {
  height: 50px;
  width: 190px;
  border-radius: 8px;
  padding: 8px, 16px, 8px, 16px;
}

.upload-btn {
  border: 0.756458px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
}

.upload-container {
  width: 50%;
  padding: 0.5%;
  margin-right: 16px;
}

.upload-btn:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.upload-input {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #03014c;
  margin-top: 10px;
}
</style>
