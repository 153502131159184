<template>
    <div id="IndicationResultPrintTemplate" class="m-auto">
      <!-- chidinh -->
      <div class="row text-black font-tnr">
        <div>
          <div>
            <div class="col-12 row mb-3 m-0">
              <div class="col-5 d-flex">
                <div class="text-center">
                  <p class="mb-0 fs-12 font-bold uppercase">{{$globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge : ''}}</p>
                  <p class="mb-0 fs-12 uppercase">
                    {{$globalClinic && $globalClinic.organization ? $globalClinic.organization.name : ''}}
                  </p>
                  <p class="mb-0 fs-12">
                    {{ $globalClinic ? $globalClinic.name : "" }}
                  </p>
                </div>
              </div>
              <div class="col-7 flex justify-content-end">
                <div>
                  <svg class="code w-52 mr-2 height-48" id="barcode"></svg>
                  <div>
                    <p class="mb-0 fs-12">PID: {{ person && person.code }}</p>
                    <p class="mb-0 fs-12">
                      Số hồ sơ:
                      {{
                        indication_order &&
                        indication_order.medical_record &&
                        indication_order.medical_record.medical_record_code
                      }}
                    </p>
                    <!-- <p class="mb-0 fs-12">Số phiếu: {{ indication_order && indication_order.code }}</p> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- // -->
            <div class="col-12 temp-name">
              <p class="mb-2 text-center text-uppercase font-weight-bold fz-18">
                kết quả {{category && category.indication_class && category.indication_class.name}}
              </p>
              <!-- <div class="flex justify-center mb-2">
                <div class="flex items-center mr-3">
                  <div class="w-3 h-3 border-box mr-1"></div>
                  <span class="fs-12">Cấp cứu</span>
                </div>
                <div class="flex items-center">
                  <div class="w-3 h-3 border-box mr-1"></div>
                  <span class="fs-12">Thường</span>
                </div>
              </div> -->
            </div>
            <!-- // -->
            <div class="mb-0 m-0">
              <div class="row p-0 m-0">
                <div class="col-8 m-0 p-0">
                  <div class="flex pl-0">
                    <p class="fs-12 w-20 mb-0">Họ tên:</p>
                    <p class="fs-12 mb-0 font-bold">
                      {{ person && person.name }}
                    </p>
                  </div>
                </div>
                <div class="col-4 m-0 p-0">
                  <div class="flex pl-0">
                    <p class="fs-12 mr-2 mb-0">
                      Tuổi: {{ person && person.age ? person.age : "" }}
                    </p>
                    <p class="fs-12 mb-0">
                      Giới tính:
                      {{ appUtils.getGender(person && person.gender) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex pl-0">
                <p class="fs-12 w-20 mb-0">Địa chỉ:</p>
                <p class="fs-12 mb-0">{{ person && person.address }}</p>
              </div>
              <div class="row m-0 p-0">
                <div class="col-8 m-0 p-0">
                  <div class="flex pl-0">
                    <p class="fs-12 w-20 mb-0">Số thẻ BHYT:</p>
                    <p class="fs-12 mb-0">
                      {{ person && person.insurance_number }}
                    </p>
                  </div>
                </div>
                <div class="col-4 m-0 p-0">
                  <p class="fs-12 mb-0">
                    Ngày hết hạn:
                    {{
                      person &&
                      person.insurance_expire &&
                      moment(person && person.insurance_expire).format(
                        "DD-MM-YYYY"
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="row m-0 p-0">
                <div class="col-8 m-0 p-0">
                  <div class="flex pl-0">
                    <p class="fs-12 w-20 mb-0">Phòng:</p>
                    <p class="fs-12 mb-0">
                      {{
                        medical_record_master &&
                        medical_record_master.admission_room
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-4 m-0 p-0">
                  <p class="fs-12 mb-0">
                    Giường:
                    {{
                      medical_record_master &&
                      medical_record_master.admission_bed
                    }}
                  </p>
                </div>
              </div>
              <div class="flex pl-0">
                <p class="fs-12 w-20 mb-0">Chẩn đoán:</p>
                <p class="fs-12 mb-0">
                  {{
                    medical_record_master &&
                    medical_record_master.disease_main_name
                  }}
                </p>
              </div>
              <div class="flex pl-0">
                <p class="fs-12 w-20 mb-0">Chỉ định:</p>
                <p class="fs-12 mb-0 font-bold">
                  {{ indication && indication.name }}
                </p>
              </div>
              <!-- <div>
                <p class="fs-12 mb-3">Bệnh kèm theo:</p>
              </div> -->
            </div>
            <div class="mt-3">
              <div
                v-if="examination_results && examination_results.conclusion"
                v-html="examination_results.conclusion.description"
              ></div>
            </div>
            <div class="mt-3">
              <p class="fs-12 w-20 mb-1 text-decoration-underline font-bold">
                Kết luận:
              </p>
              <p
                class="fs-12 mb-3 font-bold"
                v-if="examination_results && examination_results.conclusion"
              >
                {{ examination_results.conclusion.conclusion }}
              </p>
            </div>
            <!-- // -->
            <div class="flex justify-between">
              <div>
                <!-- <p class="italic fs-12 mt-1 font-bold">Hướng dẫn: </p> -->
              </div>
              <div class="text-center mt-3">
                <p class="mb-0 fs-12">{{ getHMDMYVN() }}</p>
                <p class="mb-5 fs-12 font-bold">BÁC SĨ ĐIỀU TRỊ</p>
                <p class="fs-12">{{ treat_doctor && treat_doctor.name }}</p>
              </div>
            </div>
          </div>
          <div class="print:hidden">
            <div class="d-flex justify-content-end mt-4">
              <div>
                <button class="btn bg-pri text-white" @click="print">
                  In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import appUtils from '../../../../utils/appUtils'
import JsBarcode from 'jsbarcode'
import {mixinPrint} from '../../../../utils/mixinPrint'

export default {
  name: 'IndicationResultPrintTemplate',
  mixins: [mixinPrint],
  data () {
    return {
      indication: {},
      dialogVisible: false,
      loading: false,
      appUtils,
      moment
    }
  },
  mounted () {
    this.getIndicationById(this.$route.params.id)
  },
  computed: {
    indication_order () {
      return this.indication?.indication_order
    },
    medical_record () {
      return this.indication?.indication_order?.medical_record
    },
    treat_doctor () {
      return this.medical_record?.treat_doctor
    },
    medical_record_master () {
      return this.medical_record?.medical_record_master
    },
    person () {
      return this.medical_record?.person
    },
    examination_results () {
      return this.indication?.examination_results
    },
    category () {
      return this.indication?.indication_category
    }
  },
  methods: {
    generateBarcode () {
      if (this.medical_record.medical_record_code) {
        this.$nextTick(() => {
          JsBarcode('#barcode', this.medical_record.medical_record_code, {
            fontSize: 0
          })
        })
      }
    },
    async getIndicationById (id) {
      if (!id) return

      this.loading = true
      await this.$rf.getRequest('DoctorRequest').getIndicationById(id)
        .then(res => {
          this.indication = res.data
          setTimeout(() => {
            this.generateBarcode()
          }, 100)
          setTimeout(() => {
            this.print()
          }, 200)
        })
        .catch(error => {
          console.log('getIndication', error)
        }).finally(() => {
          this.loading = false
        })
    },
    print () {
      const tempTitle = document.title
      document.title = `KETQUA_${moment().format('DDMMYYYY')}.pdf`
      window.print()
      document.title = tempTitle
    },
    getHMDMYVN (date_time) {
      let dt = window.moment(date_time || undefined)
      let hm = dt.clone().format('HH:mm')
      let d = dt.clone().format('DD')
      let m = dt.clone().format('MM')
      let y = dt.clone().format('YYYY')
      return hm + ' Ngày ' + d + ' tháng ' + m + ' năm ' + y
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .height-48 {
    height: 48px;
  }
  @media print {
    .el-dialog__heasder {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    }
  #IndicationResultPrintTemplate,
  #IndicationResultPrintTemplate p,
  #IndicationResultPrintTemplate div,
  #IndicationResultPrintTemplate b,
  #IndicationResultPrintTemplate i,
  #IndicationResultPrintTemplate h1,
  #IndicationResultPrintTemplate h2,
  #IndicationResultPrintTemplate h3,
  #IndicationResultPrintTemplate h4,
  #IndicationResultPrintTemplate h5,
  #IndicationResultPrintTemplate h6,
  #IndicationResultPrintTemplate span,
  #IndicationResultPrintTemplate strong{
    font-family: 'Tinos', serif !important;
  }
}
</style>